//https://netbasal.com/make-your-angular-forms-error-messages-magically-appear-1e32350b7fa5

import { NgModule } from '@angular/core';

import { ControlErrorComponent } from '../components/control-error.component';
import { ControlErrorContainerDirective } from './control-error-container.directive';
import { ControlErrorsDirective } from './control-errors.directive';

@NgModule({
  declarations: [ControlErrorComponent, ControlErrorContainerDirective, ControlErrorsDirective],
  exports: [ControlErrorComponent, ControlErrorContainerDirective, ControlErrorsDirective],
})
export class ControlErrorModule { }
