//https://netbasal.com/make-your-angular-forms-error-messages-magically-appear-1e32350b7fa5

import { ComponentRef, Directive, Optional, ViewContainerRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { ControlErrorComponent } from '../components/control-error.component';
import { ControlErrorContainerDirective } from './control-error-container.directive';
import { ValidatedFormControl } from '../validators/validated-form-control';

@Directive({
  selector: '[formControl], [formControlName]'
})
export class ControlErrorsDirective {
  ref?: ComponentRef<ControlErrorComponent>;
  container: ViewContainerRef;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private vcr: ViewContainerRef,
    //private injector: ComponentFactoryResolver,
    private control: NgControl,

    @Optional() controlErrorContainer?: ControlErrorContainerDirective
    )
  {
    this.container = controlErrorContainer?.vcr ?? vcr;
  }

  ngOnInit() {
    this.control.valueChanges
      ?.pipe(takeUntil(this.destroy$))
      .subscribe((v) => {
        const errors = this.control.errors ?? {};
        if (this.control.errors) {
          let text = '';
          Object.keys(errors)
            .forEach((k) => {
              let message = errors[k];
              if (!message || typeof message != 'string') {
                message = this.getErrorMessage(k, this.control.control as ValidatedFormControl);
              }
              text += message + '<br/>'
            })
          this.setError(text);
        } else if (this.ref) {
          this.setError();
        }
      });
  }

  //get later messages from server
  getErrorMessage(type: string, control?: ValidatedFormControl): string {
    //try get server message
    let message = 'validator is unknown';

    if (control) {
      message = control.serverValidators.find(v => v.validator === type).message;
    }

    if (!message) {
      switch (type) {
        case 'required':
          message = 'This field is required';
          break;
        _: message = 'This field is invalid';
      }
    }

    message = message.replace("'{PropertyName}'", 'The value');

    return message;
  }

  setError(error?: string) {

    if (!this.ref) {
      //const factory = this.injector.resolveComponentFactory(ControlErrorComponent);
      this.ref = this.container.createComponent(ControlErrorComponent);
    }

    this.ref.instance.html = error??'';
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }  
}
