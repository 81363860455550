import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";

@Component({
  template: `<small class="p-error block" [class.hidden]="_hide" [innerHTML]="_html"></small>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlErrorComponent implements OnInit {

  _html = '';
  _hide = true;

  @Input() set html(value: string) {
    if (value !== this._html) {
      this._html = value;
      this._hide = !value;
      this.cdr.detectChanges();
    }
  };

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() { }

}
